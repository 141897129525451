:root {
  --fyb_teal: #4085c6;
  --fyb_darkblue: #0f2840;
  --fyb_gray: #f8f9fa;
  --fyb_lightgray: rgb(204, 204, 204);
  --fyb_p_error_font_size: 14px;
  --fyb_p_font_size: 12px;
  --fyb_h1_font_size: 24px;
  --fyb_h2_font_size: 20px;
  --fyb_h3_font_size: 16px;
  --fyb_h4_font_size: 14px;
}
*{
  box-sizing: border-box;
}
.whatsapp_logo {
  max-height: 25px;
}

.fyb_logo_nav {
  width: 140px;
}

.fyb_dashboard_top_navigation {
  background: var(--fyb_darkblue);
}

.fyb_main_top_navigation {
  background: var(--fyb_darkblue);
  padding-left: 10%;
  padding-right: 10%;
}

.fyb_main_top_navigation_toggler {
  background: white;
}

.fyb_main_section_primary {
  margin-top: 50px;
  background: var(--fyb_teal);
}

.fyb_main_section_primary h2 {
  color: white;
}

.fyb_main_section_secondary {
  margin-top: 50px;
  background: var(--fyb_gray);
}

.fyb_main_section_secondary h2 {
  color: black;
}

.nav-toggler-icon {
  color: white;
}

.fyb_profile_picture {
  max-height: 100px;
  border-radius: 50%;
}

.fyb_logo_center {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 10px;
}

.v2_dashboard_body_box {
  margin-top: 20px !important;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  width: 60%;
  max-width: 1100px;
  min-width: 320px;
  display: block;
  background: white;
  color: black;
  border-color: var(--fyb_darkblue);
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.v2_dashboard_body_buttons {
  margin-left: 5px;
  margin-right: 5px;
}
.v2_dashboard_affiliates_upload_file_mobile {
  float: right;
  color: #ffffff;
}

.v2_dashboard_body_box p {
  display: inline-block;
  word-break: break-word;
}

.v2_dashboard_body_box hr {
  height: 2px;
  color: black;
}

.v2_dashboard_background {
  background: #0f2840;
  color: white;
}

.fyb_large_button {
  width: 100%;
}

.v2_component_form_input {
  margin-top: 10px;
}

.v2_dashboard_navigation_top_link {
  font-size: var(--fyb_h3_font_size);
}

.fyb_h1 {
  font-size: var(--fyb_h1_font_size);
}

.fyb_h2 {
  font-size: var(--fyb_h2_font_size);
}

.fyb_h3 {
  font-size: var(--fyb_h3_font_size);
}

.fyb_h4 {
  font-size: var(--fyb_h4_font_size);
}

h5 {
  font-size: var(--fyb_h5_font_size);
}

.fyb_h5 {
  font-size: var(--fyb_h5_font_size);
}

.fyb_h6 {
  font-size: var(--fyb_h6_font_size);
}

.fyb_p {
  font-size: var(--fyb_p_font_size);
}

//Dashboard Navigation Top
.dashboard_navigation_top {
  background-color: #0f2840;
  color: #ffffff;
}

.dashboard_navigation_top_logo {
  width: 7vw;
  margin-left: 2vw;
}

.dashboard_navigation_top_profile_logo {
  max-height: 50px;
  border-radius: 50%;
  font-size: var(--fyb_h5_font_size);
}

.dashboard_navigation_top_dropdown {
  margin-right: 1vw;
}

//Dashboard Navigation Left
//Dashboard Navigation Container

.dropdown-item {
  font-size: var(--fyb_h4_font);
}

.dashboard_navigation_container {
  background-color: #083d6e;
  min-width: 100px;
  max-width: 20%;
  padding-left: 0.2vw;
  padding-top: 0.2vw;
  word-break: break-word;
}

.table {
  //    display: block !important;
  overflow-x: visible !important;
  width: 100% !important;
}

.dashboard_navigation_container hr {
  background-color: #ffffff;
}

// Main Container for Dashboard
.dashboard_main_container {
  max-width: 95% !important;
}

.dashboard_main_container_h1 {
  color: #083d6e;
}

.dashboard_form_error_message {
  font-size: var(--fyb_p_error_font_size);
  color: red;
  margin-bottom: 0;
}

.dashboard_main_container_button {
  color: #ffffff;
  margin-right: 0.5vw;
  margin-top: 10px;
}

.dashboard_main_container_table {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.dashboard_main_container_table thead {
  font-size: var(--fyb_p_font);
  background-color: var(--fyb_teal);
  color: #ffffff;
}

.dashboard_main_container_table tbody {
  font-size: var(--fyb_p_font_size);
}

.dashboard_main_container_result_link {
  cursor: pointer;
}

.dashboard_main_container_form_select {
  font-size: var(--fyb_p_font_size);
}

// Dashboard Modals
.modal-title {
  font-size: var(--fyb_p_font_size);
}

// Buttons for Dashbord

.btnDash {
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: #fff;
    margin: 0 0 0 0.8vw;
  }
  p {
    font-size: 0.8vw;
    color: #fff;
    margin: 0 0 0 0.8vw;
  }
}

.dropdown-menu {
  min-width: auto;
}

.dropdown-menu[data-bs-popper] {
  right: 0;
  left: auto;
}


.form-label {
  font-size: 1rem;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #2684ff;
  outline: 0;
  box-shadow: 0 0 0 1px #2684ff;
}

.form-select:focus {
  border-color: #2684ff;
  outline: 0;
  box-shadow: 0 0 0 1px #2684ff;
}

.input-group .form-control.is-invalid {
  z-index: 0;
}

.PhoneInput {
  &Country {
    min-width: 30px;
    &Icon {
      margin-left: 0.3vw;
    }
  }
  input {
    display: block;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
      color: #212529;
      background-color: #fff;
      border-color: #2684ff;
      outline: 0;
      box-shadow: 0 0 0 1px #2684ff;
    }
  }
}
.invalidSelect {
  border: 1px solid #dc3545;
  border-radius: 5px;
  &:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
  overflow: hidden;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.cards-select_container{
  display: flex;
  gap: 35px;
  overflow: auto;
  .card-select{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    max-width: 400px;
    border: 1px solid var(--fyb_lightgray);
    cursor: pointer;
    position: relative;
    &:hover{
      padding: 10px;
      border: 1px solid var(--fyb_teal);
    }
    .card-select_checkbox{
      position: absolute;
      top: 10px;
      right: 10px;
      height: 28px;
      width: 28px;
      border: solid 2px var(--fyb_lightgray);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon{
        color: var(--fyb_teal);
        width: 100%;
        height: 100%;
      }
    }
    .card-select_img{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 120px;
        height: 120px;
        margin: 0;
      }
    }
    .card-select_title{
      font-size: 24px;
      font-weight: 600;
      color: var(--fyb_teal);
    }
    p{
      font-size: 18px;
      margin: 0;
    }
    .list-text{
      font-size: 14px;
      font-weight: 600;
    }
  }
  .selected{
    padding: 10px;
    border: 1px solid var(--fyb_teal);
    outline: 1px solid var(--fyb_teal);
  }
}
.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px; 
}

.checkbox-grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; 
}
.center-all{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 25px 0;
}


.multiStep{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .step{
    display: flex;
    align-items: center;
    .step_button-cont{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      position: relative;
      cursor: pointer;
      .step_text{
        font-size: 16px;
        font-weight: 600;
        color: var(--fyb_darkblue);
        position: absolute;
        width: 250px;
        text-align: center;
        top: -30px;
      }
      .t-pending{
        color: var(--fyb_lightgray);
      }
      .step_circle-pending{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 2px solid var(--fyb_lightgray);
        background: var(--fyb_lightgray);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
        .icon{
          color: var(--fyb_teal);
          width: 60%;
          height: 60%;
        }
      }
      .step_circle-current{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 8px solid var(--fyb_teal);
        background: var(--fyb_lightgray);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
        .icon{
          color: var(--fyb_gray);
          width: 60%;
          height: 60%;
        }
      }
      .step_circle-done{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 2px solid var(--fyb_teal);
        background: var(--fyb_teal);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
        .icon{
          color: var(--fyb_gray);
          width: 60%;
          height: 60%;
        }
      }
    }
    .step_line{
      width: 100px;
      height: 8px;
      margin: 0 5px;
      border-radius: 4px;
      background: var(--fyb_lightgray);
      overflow: hidden;
      .step_line_progress-pending {
        width: 0%;
        height: 100%;
        background: var(--fyb_lightgray);
        transition: all 0.3s ease-in-out;
      }
      .step_line_progress-current {
        width: 0%;
        height: 100%;
        background: var(--fyb_teal);
        transition: all 0.3s ease-in-out;
      }
      .step_line_progress-done {
        width: 100%;
        height: 100%;
        background: var(--fyb_teal);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.path-step-done {
  fill: none;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: draw 1s forwards;
}

@keyframes draw {
  to {
      stroke-dashoffset: 0;
  }
}

.icon-verified{
  color: var(--fyb_teal);
  font-size: 24px;
}

.header-card_section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  p{
    margin: 0;
  }
  svg{
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.contact-td{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--fyb_teal);
  font-size: 20px;
  padding: 0;
  svg{
    cursor: pointer;
  }
}

.link{
  color: var(--fyb_teal);
  cursor: pointer;
  text-decoration: underline;
}

.button-delete{
  font-size: 18px;
  color: var(--bs-red);
}

.best-quote {
  position: absolute;
  top:10px;
  left: 10px;
}

.image-company{
  width: 120px;
  height: 120px;
  display: flex;
  padding: 10px;
  & > img{
    border-radius: 20px;
  }
}