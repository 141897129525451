@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  src: url(../assets/fonts/Montserrat-ThinItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  src: url(../assets/fonts/Montserrat-ExtraLightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: url(../assets/fonts/Montserrat-LightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url(../assets/fonts/Montserrat-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: url(../assets/fonts/Montserrat-MediumItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src: url(../assets/fonts/Montserrat-SemiBoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url(../assets/fonts/Montserrat-BoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  src: url(../assets/fonts/Montserrat-ExtraBoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  src: url(../assets/fonts/Montserrat-BlackItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  src: url(../assets/fonts/Montserrat-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: url(../assets/fonts/Montserrat-ExtraLight.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url(../assets/fonts/Montserrat-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url(../assets/fonts/Montserrat-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/Montserrat-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url(../assets/fonts/Montserrat-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: url(../assets/fonts/Montserrat-Black.ttf) format("truetype");
}

$primary: #3e85c6;
$font-family-sans-serif: Montserrat;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "index.scss"
